import React from 'react';
import Title from '../components/Title';
import { BACKEND } from '../config';
import {Card} from 'react-bootstrap';
import { connect } from 'react-redux';
import ErrorModal from '../components/ErrorModal';
import ParishSheetForm from '../components/ParishSheetForm';
import {Spinner} from 'react-bootstrap';
import Lightbox from "react-image-lightbox";
import { Link45deg, Files, FileEarmarkPdf, SortUp } from 'react-bootstrap-icons';
import { Map, MarkerLayer, Marker, MouseControl, ZoomControl, CompassControl, KeyboardControl, SyncControl } from 'react-mapycz'
import "react-image-lightbox/style.css";
import ContactForm from '../components/ContactForm';

class Home extends React.Component{


    state = {
        editorValue: '',
        selectedId: '',
        url: '/',
        pageWithContent: {
            page: {
                title: ''
            },
            pageContents: []
        },
        error: false,
        base64Content: '',
        showModal: false,
        errorText: 'Při nahrávání farníčku došlo k chybě!',
        fetchingData: true,
        showImage: false,
    }
    
    componentDidMount(){
        this.fetchPageWithContent();
    }

    closeModal = () => {
        this.setState({showModal: false})
    }

    fetchPageWithContent(){
        fetch(`${BACKEND.ADDRESS}/page/getPageByUrl?url=${this.state.url}`)
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                this.setState({error: true})
            }
            else{
                this.setState({pageWithContent: json});
            }
        })
        .catch(error => {
            this.setState({error: true})
            console.error('error', error)
        })
        .finally(() => {
            this.setState({fetchingData: false})
        });
    }

    addNewParishSheet = () => {
        fetch(`${BACKEND.ADDRESS}/pageContent/newParishSheet`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                base64Content: this.state.base64Content, pathToFile: 'farnicek.pdf'
            }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                //  alert(json.message);
                this.setState({showModal: true})
            }
            else{
                
            }
        })
        .catch(error => this.setState({showModal: true})/*alert(error.message)*/);
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader(file);
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            }

            fileReader.onerror = (error) => {
                reject(error);
            }
        });
    }

    uploadFile = async(event) => {
        const file = event.target.files[0];
        const base64 = await this.convertBase64(file);
        this.setState({base64Content: base64});
    }
   
    isImage = (fileName) => {
        let exts = ['.jpg', '.gif', '.png', '.jpeg', '.bmp']
        return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName.toLowerCase());
    }

    showImage = (src) => {
        this.setState({src: src, showImage: true});
    }

    closeImage = () => {
        this.setState({showImage: false})
    }

    render(){

        const pageContents = this.state.pageWithContent.pageContents

        return(
            <div className='page-content'>
                {
                    !this.state.fetchingData ?
                    <>
                        <Title title={this.state.pageWithContent.page.title}/>

                        <div className='actual-item'>
                            
                                <Card style={{textAlign: 'center', margin: '0 auto', cursor: 'pointer'  }}>
                                    <Card.Body>
                                        <div onClick={()=> window.open(`${BACKEND.ADDRESS}/farnicek.pdf`, "_blank")}>
                                            <FileEarmarkPdf size={50} style={{ width: '30%', margin: '0 auto', marginBottom: '10px', color: 'red'}}/>
                                            <Card.Title><a href={`${BACKEND.ADDRESS}/farnicek.pdf`} target="_blank" className='actual-item-ref'>Nedělní zamyšlení a rozpis bohoslužeb</a></Card.Title>
                                        </div>
                                        <ParishSheetForm/>     
                                    </Card.Body>
                                </Card>
                        </div>
                                    
                        {
                            pageContents.map((pageContent, index) => {
                                let codeBlock;

                                if(pageContent.contentType == 1){
                                    codeBlock = <div className='subtitle'>{pageContent.subtitle}</div>
                                }
                                else if(pageContent.contentType == 2 ){
                                    codeBlock = <div className='text-content'>{pageContent.content}</div>
                                }
                                else if(pageContent.contentType == 3){
                                    let imageTmp = pageContent.pathToFile.split('.');
                                    let miniatureSrc = `${BACKEND.ADDRESS}/${imageTmp[0]}min.${imageTmp[1]}`;
                                    codeBlock = 
                                        this.isImage(pageContent.pathToFile) ? <img className='page-content-img' src={miniatureSrc} onClick={() => this.showImage(`${BACKEND.ADDRESS}/${pageContent.pathToFile}`)}/> :
                                        <div className='custom-element-wrapper'><a className='custom-link-file' href={`${BACKEND.ADDRESS}/${pageContent.pathToFile}`}><Files size={35} style={{marginRight: '5px', textDecoration: 'none'}}/>Stáhnout soubor: {pageContent.pathToFile}</a></div>
                                }
                                else if(pageContent.contentType == 4){
                                    codeBlock = <div className='custom-element-wrapper'><a className='custom-link' href={pageContent.content}><Link45deg size={50}/>{pageContent.content ? pageContent.content : "Neplatný odkaz"}</a></div>
                                }
                                else if(pageContent.contentType == 5){      
                                    if(pageContent.content){
                                        let listItems = pageContent.content.split("\n");
                                        listItems = listItems.filter(entry => entry.trim() != '');

                                        codeBlock = <ul className="custom-list">
                                            {
                                                listItems.map((listItem, i) => {
                                                    return <li key={i}>{listItem}</li>
                                                })
                                            }
                                        </ul>;
                                    }                                 
                                }
                                else if(pageContent.contentType == 6){
                                    if(pageContent.content){
                                        let listItems = pageContent.content.split("\n");
                                        listItems = listItems.filter(entry => entry.trim() != '');

                                        codeBlock = <table className="custom-pairs">
                                            {
                                                listItems.map((listItem, i) => {
                                                    let splittedPair = listItem.split(":");
                                                    return <tr className='custom-pair'>
                                                        <td className='custom-pair-key'>
                                                            {splittedPair[0]}:
                                                        </td>
                                                        <td className='custom-pair-value'>
                                                            {splittedPair[1]}
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </table>;
                                    }      
                                }
                                else if(pageContent.contentType == 7){
                                    if(pageContent.content){
                                        let latLong = pageContent.content.trim().split(",");

                                        if(latLong.length == 2){
                                            codeBlock =  <div className='page-content-items-map'                                             
                                            >
                                                <Map style={{margin: '0 auto'}} center={{lat: latLong[0], lng: latLong[1]}}>
                                                    <KeyboardControl/>
                                                    <ZoomControl/>
                                                    <MouseControl zoom={true} pan={true} wheel={true}/>
                                                    <CompassControl right={10} top={50}/>
                                                    <SyncControl />
                                                    <MarkerLayer>
                                                    <Marker coords={{lat: latLong[0], lng: latLong[1]}}/>
                                                    </MarkerLayer>
                                                </Map>
                                            </div>
                                        }
                                    }
                                }

                                return(
                                    <div className='page-content-items' key={index}>
                                        {codeBlock}
                                    </div>
                                )
                            })
                        }
                        {
                            this.state.showImage && 
                            <Lightbox
                                mainSrc={this.state.src}
                                onCloseRequest={this.closeImage}
                                enableZoom={true}
                            />
                        }
                    </> : <Spinner animation="border" variant="primary" size='xl'/>
                }

                <ErrorModal show={this.state.showModal} onHide={this.closeModal} text={this.state.errorText}></ErrorModal>
            </div>
        )
    }
};

export default connect(
    ({ account }) => ({ account }),
    null
  )(Home);