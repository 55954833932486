import React from 'react';
import Title from '../components/Title';
import { connect } from 'react-redux';
import GalleriesAdminPanel from '../components/GalleriesAdminPanel';
import { BACKEND } from '../config';
import history from '../history';
import {Card, Form, Row, Col, Spinner, Button} from 'react-bootstrap';
import ErrorModal from '../components/ErrorModal';

import './gallery.css';



class Galleries extends React.Component{

    state = {
        galleries: [],
        year: new Date().getUTCFullYear(),
        years: [],
        emptyText: '',
        showModal: false,
        errorText: 'Při načítání galerií došlo k chybě!',
        fetchingYears: true,
        fetchingGalleries: true
    }

    componentDidMount(){
        this.fetchYears();
        //this.fetchGalleries();
    }

    closeModal = () => {
        this.setState({showModal: false})
    }

    fetchYears = () => {
        fetch(`${BACKEND.ADDRESS}/gallery/getAllYears`)
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                this.setState({errorText: json.message, showModal: true});
            }
            else{
                let arr = json.map((item, index) => ({
                    label: item.year,
                    value: item.year
                }));
                this.setState({years: arr});
                if(arr.length > 0)
                    this.setState({year: arr[arr.length-1].value}, this.fetchGalleries);
                else
                    this.setState({fetchingGalleries: false});
            }
           
        })
        .catch(error => {
            this.setState({errorText: error, showModal: true});
            console.error('error', error)
        })
        .finally(() => {
            this.setState({fetchingYears: false});
        });
    }

    fetchGalleries = () => {
       
        fetch(`${BACKEND.ADDRESS}/gallery/getByYear?year=${this.state.year}`)
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                this.setState({errorText: json.message, showModal: true});
            }
            else{
                if(json.galleries.length === 0)
                    this.setState({emptyText: 'Na toto období není nahrána žádná galerie.'});
                this.setState({galleries: json.galleries});
            }
        })
        .catch(error => {
            this.setState({errorText: error, showModal: true});
            console.error('error', error)
        })
        .finally(() => {
            this.setState({fetchingGalleries: false})
        });
    }

    changeYear = (event) => {
        this.setState({year: event.target.value, fetchingGalleries: true}, () => this.fetchGalleries());

    }

    showGallery = (id) => {
        history.push('/galerie/' + id, );
    }

    removeGallery = (id) => {
        fetch(`${BACKEND.ADDRESS}/gallery/remove`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                galleryId: id
            }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                this.setState({showModal: true})
            }
            else{
                this.fetchGalleries();
            }
        })
        .catch(error => this.setState({showModal: true}));
    }

    render(){


        return(
            <div className='page-content'>
                {
                    !this.state.fetchingYears && !this.state.fetchingGalleries ?
                    <>
                        <Title title={"Galerie"} />
                        {
                            this.props.account.loggedIn &&
                            <GalleriesAdminPanel 
                                fetchYears={() => this.setState({fetchingYears: true}, this.fetchYears)} 
                                fetchGalleries={() => this.setState({fetchingGalleries: true}, this.fetchGalleries)}
                            />
                        }
                        <Form>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label xs={2} sm={1} md={1} lg={1} xl={1} column>Rok</Form.Label>
                                <Col xs={5} sm={3} md={2} lg={3} xl={2}>
                                    <Form.Select  value={this.state.year} onChange={this.changeYear}>
                                        {
                                            this.state.years.map((year, index) => {
                                                return <option key={index} value={year.value}>{year.label}</option>
                                            })
                                        }
                                    </Form.Select>
                                </Col>
                            </Form.Group>
                        </Form>

                        {
                            this.state.galleries.length === 0 &&
                            <div>
                                {this.state.emptyText}
                            </div>
                        }

                        <Row>
                        {
                            this.state.galleries.map((gallery, index) => {
                                return(
                                    
                                    <Col key={index} xs={12} sm={6} md={6} lg={4} xl={4} xxl={3} style={{marginBottom: '1.0rem', paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
                                        <div style={{height: '100%'}}>
                                        <Card className="gallery-card" style={{textAlign: 'center', margin: '0 auto', cursor: 'pointer', height: '100%'}}>
                                            <div className="gallery-img">
                                                <Card.Img onClick={() => this.showGallery(gallery.id)} variant="top" src={`${BACKEND.ADDRESS}/${gallery.folderName}/albumPicture.png`}/>
                                            </div>
                                            
                                            <Card.Body>
                                                <Card.Title onClick={() => this.showGallery(gallery.id)}>{gallery.title}</Card.Title> 
                                                {
                                                    this.props.account.loggedIn &&
                                                    <div style={{ width: '100%'}}>
                                                        <Button variant="danger" onClick={() => this.removeGallery(gallery.id)}>Smazat galerii</Button>
                                                    </div>
                                                }
                                            </Card.Body>
                                            
                                        </Card>
                                        </div>
                                    </Col>
                                    
                                ) 
                            })
                        }
                        </Row>
                    </> : <Spinner animation="border" variant="primary" size='xl'/>
                }

                <ErrorModal show={this.state.showModal} onHide={this.closeModal} text={this.state.errorText}></ErrorModal>
           </div>
        )
    }
};

export default connect(
    ({ account }) => ({ account }),
    null
  )(Galleries);