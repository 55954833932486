import { PUBLIC_PAGES } from '../actions/types';
import fetchStates from './fetchStates';

const DEFAULT_PUBLIC_PAGES = { pages: [] };

const publicPages = (state = DEFAULT_PUBLIC_PAGES, action) => {
  switch(action.type) {
    case PUBLIC_PAGES.FETCH:
      return { ...state, status: fetchStates.fetching };
    case PUBLIC_PAGES.FETCH_ERROR:
      return { ...state, status: fetchStates.error, message: action.message };
    case PUBLIC_PAGES.FETCH_SUCCESS:
      return { ...state, status: fetchStates.success, pages: action.pages };
    default:
      return state;
  }
};

export default publicPages;