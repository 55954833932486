import { PUBLIC_PAGES } from './types';
import { BACKEND } from '../config';

export const fetchPublicPages = () => dispatch => {
  dispatch({ type: PUBLIC_PAGES.FETCH });
  console.log("fetch")

  return fetch(`${BACKEND.ADDRESS}/page/getAllPublic`)
    .then(response => response.json())
    .then(json => {
      if (json.type === 'error') {
        dispatch({ type: PUBLIC_PAGES.FETCH_ERROR, message: json.message });
      } else {
        let mainPages = []
        let otherPages = []

        for(let i = 0; i < json.pages.length; i++){
            if(json.pages[i].url.split("/").length -1 === 1 ){
                mainPages.push(json.pages[i])
            }   
            else
                otherPages.push(json.pages[i])
        }

        mainPages = mainPages.map(obj => ({ ...obj, subMenus: [] }));

        for(let i = 0; i < otherPages.length; i++){
            for(let j = 0; j < mainPages.length; j++){
                if(otherPages[i].url.includes(mainPages[j].url) && mainPages[j].url !== "/")
                    mainPages[j].subMenus.push(otherPages[i]);
            }
        }

        dispatch({ type: PUBLIC_PAGES.FETCH_SUCCESS, pages: mainPages });
      }
    })
    .catch(error => dispatch({ type: PUBLIC_PAGES.FETCH_ERROR, message: error.message }));
}

