import React from "react";
import { dcm, doo, bohosluzby } from "../assets";

import './footer.css';

class Footer extends React.Component {
 
    render() {
        return (
            <div className="footer-items">
                <div>
                    <img src={dcm} onClick={()=> window.open("http://dcm.doo.cz/")} title="Diecézní centrum pro mládež Ostrava"></img>
                </div>
                <div>
                    <img src={doo} onClick={()=> window.open("https://doo.cz/")} title="Diecéze Ostravsko - Opavská"></img>
                </div>
                <div>
                    <img src={bohosluzby} onClick={()=> window.open("https://bohosluzby.cirkev.cz/")} title="Katolické bohoslužby v České republice"></img>
                </div>
            </div> 
        );
    }
}

export default Footer;