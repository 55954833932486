import React from "react";
import {createStore, compose, applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import { render } from "react-dom";
import thunk from 'redux-thunk';
import rootReducer from "./reducers";

import { Router, Switch, Route, Redirect} from "react-router-dom";
import { fetchAuthenticated } from "./actions/account";
import history from "./history";

import './index.css';
import CustomNavbar from "./components/CustomNavbar";
import Footer from "./components/Footer";

import PageEditor from "./pages/PageEditor";
import Home from "./pages/Home";
import Galleries from "./pages/Galleries";
import Gallery from "./pages/Gallery";
import CustomizablePage from "./pages/CustomizablePage";
import PageContentEditor from "./pages/PageContentEditor";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BACKEND } from './config';
import { pdfsymbol } from "./assets";
import {Card} from 'react-bootstrap';
import { FileEarmarkPdf } from 'react-bootstrap-icons';
import ParishSheetForm from "./components/ParishSheetForm";


const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(thunk))  
);

const AuthRoute = props => {
    if(!store.getState().account.loggedIn){
        return <Redirect to={{pathname: '/'}}/>
    }

    const {component, path} = props;

    return <Route path={path} component={component}/>;
}


const pageRef = React.createRef();


function scrollToPage() {
    setTimeout(() =>  window.scrollTo({ top: pageRef.current.offsetTop - 60, behavior: 'smooth' }), 200);
    
    
}

store.dispatch(fetchAuthenticated())
.then(() => {
    render(
        <Provider store={store}>
            <Router history={history}>
                <CustomNavbar onLinkClick={()=>scrollToPage()}></CustomNavbar>
                <div className="top-image">
                    <div className="top-image-text-wrapper">
                        <h3 className="top-text-h3">Vítejte na stránkách farnosti</h3>
                        <h1 className="top-text-h1">ve Staré Vsi nad Ondřejnicí</h1>
                    </div>
                </div>
                
                <div ref={pageRef} className='page'>
                    <div className="left-attachments">
                        <Card className="left-attachment">
                            <Card.Body>  
                                <div onClick={()=> window.open(`${BACKEND.ADDRESS}/farnicek.pdf`, "_blank")}>
                                    <FileEarmarkPdf size={50} style={{ width: '30%', margin: '0 auto', marginBottom: '10px', color: 'red'}}/>
                                    <Card.Title><a href={`${BACKEND.ADDRESS}/farnicek.pdf`} target="_blank" className='actual-item-ref'>Nedělní zamyšlení a rozpis bohoslužeb</a></Card.Title>   
                                </div>                          
                                <ParishSheetForm/>
                            </Card.Body>
                        </Card>
                    </div>
                    <Switch>
                        <Route exact path='/' component={Home} />
                        <Route exact path='/galerie' component={Galleries} />
                        <Route exact path='/galerie/:id' component={Gallery} />
                        <AuthRoute path='/page-editor' component={PageEditor} />
                        <AuthRoute path='/page-content-editor' component={PageContentEditor} />
                        <Route component={CustomizablePage}/>
                    </Switch>
                    <div className="right-attachments">

                    </div>
                </div>
                
                <Footer></Footer>
            </Router>

            
       
        </Provider>,
        document.getElementById('root')
    );
});
