import React from 'react';
import PageRow from '../components/PageRow';
import Title from '../components/Title';
import { connect } from 'react-redux';
import { fetchPublicPages } from '../actions/publicPages';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { BACKEND } from '../config';
import {Spinner, Button, Row, Col} from 'react-bootstrap';


class PageEditor extends React.Component{


    state = {
        pages: [],
        pairsToUpdate: [],
        error: false,
        errorText: 'Při zpracování obsahu došlo k chybě!',
        fetchingData: true
    }

    componentDidMount(){
        this.fetchAllPages();
    }

    fetchAllPages = () => {
        fetch(`${BACKEND.ADDRESS}/page/getAll`)
        .then(response => response.json())
        .then(json => {    
            if(json.type === 'error'){
                this.setState({error: true})
            }
            else{
                this.setState({pages: json.pages});
            }
        })
        .catch(error => {
            this.setState({error: true})
            console.error('error', error)
        })
        .finally(() => {
            this.setState({fetchingData: false})
        });
    }

    addNewPage = () => {
        fetch(`${BACKEND.ADDRESS}/page/new`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                title: 'Neznámý', isPublic: false, url: 'neznámé', pageOrder: this.state.pages.length
            }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                this.setState({error: true})
            }
            else{
                let page = json.page;
                let pages = this.state.pages
                pages.push(page);
                this.setState({pages: pages});
            }
        })
        .catch(error => {
            this.setState({error: true})
            console.error('error', error)
        });
    }

    saveNewOrder = () => {
        fetch(`${BACKEND.ADDRESS}/page/updateOrder`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                pairsToUpdate: this.state.pairsToUpdate
            }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                this.setState({error: true})
            }
            else{
                this.props.fetchPublicPages();
            }
        })
        .catch(error => {
            this.setState({error: true})
            console.error('error', error)
        });
    }

    updatePagesArray = (index) => {
        let pages = this.state.pages;
        pages.splice(index, 1);
        this.setState({pages: pages});
        this.props.fetchPublicPages();
    }

    handleOnDragEnd = (result) => {
        if (!result.destination) return;
        let pages = this.state.pages;
        const items = Array.from(pages);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        let pairsToUpdate = []
        
        for(let i = 0; i < items.length; i++){
            items[i].pageOrder = i;
            pairsToUpdate.push({pageId: items[i].id, pageOrder: i});
        }

        this.setState({pages: items, pairsToUpdate: pairsToUpdate});
      }

    render(){
        const pages = this.state.pages;

        return(
            <div className='page-content'>
                {
                    !this.state.fetchingData ?
                    <>
                        <Title title="Editace stránek"/>
                        <Row className='mb-2' style={{textAlign: 'right'}}>
                            <Col sm={12}>
                                <Button className='mb-2' variant="success" onClick={this.addNewPage}>
                                    Vytvořit novou stránku
                                </Button>
                                <Button className='mb-2' variant="primary" onClick={this.saveNewOrder}>
                                    Uložit nové pořadí
                                </Button> 
                            </Col> 
                        </Row>

                        <DragDropContext onDragEnd={this.handleOnDragEnd}>
                            <Droppable droppableId="pages">
                                {(provided) => (
                                <div className="pages" {...provided.droppableProps} ref={provided.innerRef}>
                                    {
                                        pages.map((page, index) => {
                                            return (
                                                <Draggable key={page.id} draggableId={page.id.toString()} index={index}>
                                                {(provided) => (
                                                    <div className="page-content-row" key={page.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <PageRow page={page} index={index} updatePagesArray={this.updatePagesArray}/>
                                                    </div>
                                                )}
                                                </Draggable>
                                            );
                                        })
                                    }
                                {provided.placeholder}
                                </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </> : <Spinner animation="border" variant="primary" size='xl'/>
                }
                
            </div>
        )
    }
};

export default connect(
    null,
    {fetchPublicPages}
  )(PageEditor);