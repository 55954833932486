/*const BACKEND = {
    ADDRESS: 'https://farnost-5645.rostiapp.cz'
};
*/


const BACKEND = {
    ADDRESS: 'https://farnoststaravesno.cz'
};


/*
const BACKEND = {
    ADDRESS: 'http://localhost:8080'
};*/

export {BACKEND};