import React from 'react';
import Title from '../components/Title';
import Content from '../components/Content';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import history from '../history';
import { BACKEND } from '../config';
import {Button, Spinner, Row, Dropdown, DropdownButton, Col} from 'react-bootstrap';

class PageContentEditor extends React.Component{

    state = {
        pageContents: [],
        pageId: this.props.location.pathname.split('/').pop(),
        pairsToUpdate: [],
        error: false,
        page:{
            title: ''
        },
        errorText: 'Při zpracování obsahu došlo k chybě!',
        fetchingData: true
    }

    componentDidMount(){
        this.getPageWithContent();
    }

    getPageWithContent = () => {
        fetch(`${BACKEND.ADDRESS}/page/getPage?pageId=${this.state.pageId}`, {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                this.setState({error: true})
            }
            else{
                this.setState({page: json.page, pageContents: json.pageContents});
            }
        })
        .catch(error => {
            this.setState({error: true})
            console.error('error', error)
        })
        .finally(() => {
            this.setState({fetchingData: false})
        });
    }

    addPageContent = (selected) => {
        console.log(selected)
        let pageContent = {subtitle: "", isPublic: true, content: "", pathToFile: "", orderOnPage: this.state.pageContents.length, contentType: selected, pageId: this.state.pageId, base64Content: ""}
        this.addNewPageContent(pageContent);
    }

    addNewPageContent = (pageContent) => {
        
        fetch(`${BACKEND.ADDRESS}/pageContent/new`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(
                pageContent
            ),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                this.setState({error: true})
            }
            else{
                let pageContents = this.state.pageContents;
                pageContents.push(json.pageContent);
                this.setState({pageContents: pageContents});
            }
        })
        .catch(error => {
            this.setState({error: true})
            console.error('error', error)
        });

    }

    updatePageContentsArray = (index) => {
        let pageContents = this.state.pageContents;
        pageContents.splice(index, 1);
        this.setState({pageContents: pageContents});
    }

    handleOnDragEnd = (result) => {
        if (!result.destination) return;
        let pageContents = this.state.pageContents;
        const items = Array.from(pageContents);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        let pairsToUpdate = []
        
        for(let i = 0; i < items.length; i++){
            items[i].pageOrder = i;
            pairsToUpdate.push({pageContentId: items[i].id, orderOnPage: i});
        }

        this.setState({pageContents: items, pairsToUpdate: pairsToUpdate});
      }

    viewPage = () => {
        history.push(this.state.page.url);
    }

    saveNewOrder = () => {
        fetch(`${BACKEND.ADDRESS}/pageContent/updateOrder`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                pairsToUpdate: this.state.pairsToUpdate
            }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                this.setState({error: true})
            }
        })
        .catch(error => {
            this.setState({error: true})
            console.error('error', error)
        });
    }

    render(){

        const pageContents = this.state.pageContents;

        return(
            <div className='page-content'>
                {
                    !this.state.fetchingData ? 
                    <>
                        <Title title={this.state.page.title} />
                        <Row className='mb-2 page-content-menu'>
                            <Col xs={12} sm={4} style={{textAlign: 'left'}} className='page-content-menu-left'>
                                <DropdownButton style={{marginLeft: '-10px'}} id="dropdown-basic-button" title="Přidat obsah" onSelect={this.addPageContent} className='mb-2'>
                                    <Dropdown.Item eventKey='1'>Nadpis</Dropdown.Item>
                                    <Dropdown.Item eventKey='2'>Text</Dropdown.Item>
                                    <Dropdown.Item eventKey='3'>Soubor</Dropdown.Item>
                                    <Dropdown.Item eventKey='4'>Odkaz</Dropdown.Item>
                                    <Dropdown.Item eventKey='5'>Seznam</Dropdown.Item>
                                    <Dropdown.Item eventKey='6'>Dvojice název - hodnota</Dropdown.Item>
                                    <Dropdown.Item eventKey='7'>Mapa</Dropdown.Item>
                                    <Dropdown.Item eventKey='8'>Kontaktní formulář</Dropdown.Item>
                                    <Dropdown.Item eventKey='9'>Textový obsah - Nové!</Dropdown.Item>
                                </DropdownButton>
                            </Col>
                            <Col xs={12} sm={8} style={{textAlign: 'right'}} className='page-content-menu-right'>
                                <Button variant="success" onClick={this.saveNewOrder} className='mb-2'>
                                    Uložit nové pořadí
                                </Button> 
                                <Button variant="primary" onClick={this.viewPage} className='mb-2'>
                                    Zobrazit stránku
                                </Button>
                            </Col>
                        </Row>
                    
                        <DragDropContext onDragEnd={this.handleOnDragEnd}>
                            <Droppable droppableId="page-contents">
                                {(provided) => (
                                <div className="page-contents" {...provided.droppableProps} ref={provided.innerRef}>
                                    {
                                        pageContents.map((pageContent, index) => {
                                            return (
                                                <Draggable key={pageContent.id} draggableId={pageContent.id.toString()} index={index}>
                                                {(provided) => (
                                                    <div className='page-content-row' key={pageContent.id} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <Content index={index} pageContent={pageContent} updatePageContentsArray={this.updatePageContentsArray}/>
                                                    </div>
                                                )}
                                                </Draggable>
                                            );
                                        })
                                    }
                                {provided.placeholder}
                                </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </> : <Spinner animation="border" variant="primary" size='xl'/>
                }      
            </div>   
        )
    }
};

export default PageContentEditor;