import React from "react";
import ErrorModal from "./ErrorModal";
import {BACKEND} from '../config';
import {Alert, Row, Col, Form, Button} from 'react-bootstrap';

class AdminImagePanel extends React.Component {

    state = {
        showModal: false,
        errorText: 'Při aktualizaci galerie došlo k chybě!'
    }

    updateGalleryImage = () => {
        fetch(`${BACKEND.ADDRESS}/gallery/updateImage`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                folderName: this.props.gallery.folderName, imageName: this.props.image.imageName
            }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                this.setState({showModal: true})
            }
            else{
            }
        })
        .catch(error => this.setState({showModal: true}));
    }

    removeGalleryImage = () => {
        fetch(`${BACKEND.ADDRESS}/image/remove`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                imageId: this.props.image.id
            }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                this.setState({showModal: true})
            }
            else{
                this.props.fetchGalleryImages();
            }
        })
        .catch(error => this.setState({showModal: true}));
    }

    closeModal = () => {
        this.setState({showModal: false})
    }


    render() {
        return (
            <div className="admin-image-panel" style={{ textAlign: 'center', border: '1px solid rgba(0,0,0,.125)', borderRadius: ' 0.25rem', padding: '0px 10px 0px 10px'}}>
                <Row>
                    <Col sm={12}>
                        <Button variant="primary" size="sm" style={{margin: '10px 0px 0px 0px', width: '100%'}} onClick={this.updateGalleryImage}>
                            Nastavit jako obrázek alba
                        </Button>
                    </Col>
                    <Col  sm={12}>
                        <Button variant="danger" size="sm" style={{margin: '10px 0px 10px 0px', width: '100%'}} onClick={this.removeGalleryImage}>
                            Smazat obrázek z alba
                        </Button>
                    </Col>
                </Row>
                    
                    

                    <ErrorModal show={this.state.showModal} onHide={this.closeModal} text={this.state.errorText}></ErrorModal>
            </div> 
        );
    }
}

export default AdminImagePanel;