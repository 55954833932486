import React from 'react';
import Title from '../components/Title';
import { connect } from 'react-redux';
//import GalleriesAdminPanel from '../components/GalleriesAdminPanel';
import { BACKEND } from '../config';
import GalleryAdminPanel from '../components/GalleryAdminPanel';
import {Spinner, Row, Col, Figure} from 'react-bootstrap';

import './gallery.css';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import AdminImagePanel from '../components/AdminImagePanel';



class Gallery extends React.Component{

    state = {
        galleryId: this.props.match.params.id,
        gallery: {
            title: ''
        },
        images: [],
        error: false,
        errorText: 'Při načítání obsahu galerie došlo k chybě!',

        src: '',
        showModal: false,
        fetchingGallery: true,
        fetchingImages: true
    }

    componentDidMount(){
        this.fetchGallery();
    }

    closeModal = () => {
        this.setState({showModal: false})
    }

    fetchGallery = () => {
        fetch(`${BACKEND.ADDRESS}/gallery/getById?galleryId=${this.state.galleryId}`)
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                this.setState({error: true})
            }
            else{
                this.setState({gallery: json.gallery}, ()=> this.fetchGalleryImages());
            }
        })
        .catch(error => {
            this.setState({error: true})
            console.error('error', error)
        })
        .finally(() => {
            this.setState({fetchingGallery: false})
        });
    }

    fetchGalleryImages = () => {
        fetch(`${BACKEND.ADDRESS}/image/getAll?galleryId=${this.state.galleryId}`)
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                this.setState({error: true})
            }
            else{
                let images = []

                for(let i = 0; i < json.images.length; i++){
                    const imageTmp = json.images[i].imageName.split('.');
                    images.push({src: `${BACKEND.ADDRESS}/${this.state.gallery.folderName}/${json.images[i].imageName}`, 
                    srcMin: `${BACKEND.ADDRESS}/${this.state.gallery.folderName}/${imageTmp[0]}min.${imageTmp[1]}`, 
                    imageName: json.images[i].imageName, id: json.images[i].id});
                }
                this.setState({images: images});
            }
        })
        .catch(error => {
            this.setState({error: true})
            console.error('error', error)
        })
        .finally(() => {
            this.setState({fetchingImages: false})
        });
    }

    showImage = (src, index) => {
        this.setState({src: src, showModal: true, currentImg: index});
    }

    setImgIndex = (index) => {
        this.setState({currentImg: index})
    }

    render(){
        const images = this.state.images;

        return(
            <div className='page-content'>

                {
                    !this.state.fetchingGallery && !this.state.fetchingImages ? 
                    <>
                        <Title title={this.state.gallery.title} />
                        {
                            this.props.account.loggedIn &&
                            <GalleryAdminPanel fetchGalleryImages={this.fetchGalleryImages} galleryId={this.state.galleryId}/>
                        }
                        
                        <Row>
                        {
                            images.map((image, index) => {
                                return(
                                    
                                    <Col xs={12} sm={6} md={6} lg={4} xl={4} xxl={3} style={{paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
                                        <div key={index} style={{height: '100%'}}>
                                            <Figure className="gallery-img-figure" style={{display: 'block', height: '100%'}}>
                                                <div className='gallery-img' onClick={() => this.showImage(image.src, index)}>
                                                    <Figure.Image src={image.srcMin}/>
                                                </div>
                                                {
                                                    this.props.account.loggedIn &&
                                                    <AdminImagePanel image={image} gallery={this.state.gallery} fetchGalleryImages={this.fetchGalleryImages}/>
                                                }
                                            </Figure>
                                        </div>
                                    </Col>
                                    
                                )
                                
                            })
                        }
                        </Row>
                        
                        {
                            this.state.showModal && 
                            <Lightbox
                                mainSrc={images[this.state.currentImg].src}
                                nextSrc={images[(this.state.currentImg + 1) % images.length].src}
                                prevSrc={images[(this.state.currentImg + images.length - 1) % images.length].src}
                                onCloseRequest={this.closeModal}
                                onMovePrevRequest={() => this.setImgIndex((this.state.currentImg + images.length - 1) % images.length)}
                                onMoveNextRequest={() => this.setImgIndex((this.state.currentImg + 1) % images.length)}
                                enableZoom={true}
                            />
                        }
                    </> : <Spinner animation="border" variant="primary" size='xl'/>
                }
            </div>
        )
    }
};

export default connect(
    ({ account }) => ({ account }),
    null
  )(Gallery);

  /*<ImageModal show={this.state.showModal} onHide={this.closeModal} src={this.state.src}></ImageModal>*/