import React from "react";
import {Modal, Button} from 'react-bootstrap';

class ErrorModal extends React.Component {
 
    render() {
        return (
            <Modal
                {...this.props}
                show={this.props.show}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Vyskytla se chyba!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                    {this.props.text}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default ErrorModal;