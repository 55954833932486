import React from 'react';
import { connect } from 'react-redux';
import { login } from '../actions/account';
import history from '../history';
import {Modal, Button, Form, Row, Col} from 'react-bootstrap';

class AuthModal extends React.Component {
    state = { username: '', password: '', buttonClicked: false };

    updateUsername = event => {
        this.setState({ username: event.target.value });
    }
    
      updatePassword = event => {
        this.setState({ password: event.target.value });
    }

    login = () => {
        this.setState({ buttonClicked: true });
    
        const { username, password } = this.state;

        this.setState({username: '', password: ''});
    
        this.props.login({ username, password });
        this.props.onHide();
    }

    logout = () => {
        this.props.logout();
        history.push('/');
    }

    handleKeyPress = (target) => {
        if(target.charCode === 13){
          this.login();    
        } 
    }


    render() {
        return (
            <Modal
                show={this.props.show} onHide={this.props.onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Přihlášení
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form>
                    <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label column sm={4}>Uživatelské jméno</Form.Label>
                        <Col sm={6}>
                            <Form.Control
                                type="text"
                                placeholder="login"
                                autoFocus
                                value={this.state.username}
                                onChange={this.updateUsername}
                            />
                        </Col>
                        
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput2">
                        <Form.Label column sm={4}>Heslo</Form.Label>
                        <Col sm={6}>
                            <Form.Control
                                type="password"
                                placeholder="*******"
                                value={this.state.password}
                                onChange={this.updatePassword}
                                onKeyPress={this.handleKeyPress}
                                autoComplete="on"
                            />
                        </Col>  
                    </Form.Group>
                </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={this.props.onHide}>
                        Zavřít
                    </Button>
                    <Button variant="success" onClick={this.login}>
                        Přihlásit
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default connect(
    null,
    { login }
  )(AuthModal);