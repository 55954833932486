import React from 'react';
import './pageEditor.css';
import history from '../history';
import { connect } from 'react-redux';
import { fetchPublicPages } from '../actions/publicPages';
import { BACKEND } from '../config';
import {Button, Form, Row, Col} from 'react-bootstrap';


class PageRow extends React.Component{

    state = {
        title: this.props.page.title,
        url: this.props.page.url,
        isPublic: this.props.page.isPublic,
        edit: false
    }

    updateTitle = event => {
        this.setState({title: event.target.value});
    }

    updateUrl = event => {
        this.setState({url: event.target.value});
    }

    updateIsPublic = event => {
        this.setState({isPublic: event.target.checked});
    }

    toggleEdit = () => {
        this.setState({edit: !this.state.edit});
    }

    updatePage = () => {
        fetch(`${BACKEND.ADDRESS}/page/update`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                pageId: this.props.page.id, title: this.state.title, isPublic: this.state.isPublic, url: this.state.url
            }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                alert(json.message);
            }
            else{
                this.toggleEdit();
                this.props.fetchPublicPages();
            }
        })
        .catch(error => alert(error.message));
    }

    removePage = () => {
        fetch(`${BACKEND.ADDRESS}/page/remove`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                pageId: this.props.page.id
            }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                alert(json.message);
            }
            else{
                this.props.updatePagesArray(this.props.index);
            }
        })
        .catch(error => alert(error.message));
    }

    editContent = () => {
        history.push('/page-content-editor/' + this.props.page.id, );
    }


    get SaveButton(){
        return <Button className='mb-2' variant="success" onClick={this.updatePage}>Uložit</Button>
    }

    get EditButton(){
        return <Button className='mb-2' variant="primary" onClick={this.toggleEdit}>Editovat</Button>
    }


    render(){
        return(
            <Form>
                <Form.Group as={Row} className="mb-1" controlId="formGroupTitle">
                    <Form.Label sm={2} md={2} lg={1} xl={2} xxl={1} column>Titulek</Form.Label>
                    <Col sm={4} md={4} lg={2} xl={4} xxl={2}>
                        <Form.Control className='mb-2' type="text" placeholder="Vložte titulek..." disabled={!this.state.edit} value={this.state.title} onChange={this.updateTitle}/>
                    </Col>

                    <Form.Label sm={2} md={2} lg={1} xl={2} xxl={1} column>URL</Form.Label>
                    <Col sm={4} md={4} lg={2} xl={4}  xxl={2}>
                        <Form.Control className='mb-2' type="text" placeholder="Vložte url..." disabled={!this.state.edit} value={this.state.url} onChange={this.updateUrl}/>
                    </Col>
                    
                    <Col sm={12} md={12} lg={6} xl={12} xxl={6} style={{textAlign: 'right'}}> 
                    <Form.Check className='mb-2' style={{display: 'inline-block'}} label={this.state.isPublic ? "Veřejná" : "Neveřejná"} type="switch"  disabled={!this.state.edit} checked={this.state.isPublic} onChange={this.updateIsPublic}/>
                        {
                            this.state.edit ? this.SaveButton : this.EditButton
                        }  
                        <Button className='mb-2' variant="primary" onClick={this.editContent}>Editovat obsah</Button>
                        <Button className='mb-2' variant="danger" onClick={this.removePage}>Smazat</Button>
                    </Col>     
                </Form.Group>
            </Form>
        )
    }
};

export default connect(
    null,
    {fetchPublicPages}
  )(PageRow);