const ACCOUNT = {
    FETCH: 'ACCOUNT_FETCH',
    FETCH_ERROR: 'ACCOUNT_FETCH_ERROR',
    FETCH_SUCCESS: 'ACCOUNT_FETCH_SUCCESS',
    FETCH_LOGOUT_SUCCESS: 'ACCOUNT_FETCH_LOGOUT_SUCCESS',
    FETCH_AUTHENTICATED_SUCCESS: 'ACCOUNT_FETCH_AUTHENTICATED_SUCCESS'
};

const PUBLIC_PAGES = {
    FETCH: 'PUBLIC_PAGES_FETCH',
    FETCH_ERROR: 'PUBLIC_PAGES_FETCH_ERROR',
    FETCH_SUCCESS: 'PUBLIC_PAGES_FETCH_SUCCESS'
  };


export {
    ACCOUNT,
    PUBLIC_PAGES
};