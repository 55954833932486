import React from "react";
import { BACKEND } from '../config';
import ErrorModal from "./ErrorModal";
import {Button, Form, Row, Col} from 'react-bootstrap';

class GalleriesAdminPanel extends React.Component {

    state = {
        date: new Date(),
        inputDate: new Date().toISOString().substring(0, 10),
        title: '',
        correctValues: false,
        showModal: false,
        errorText: 'Při vytváření galerie došlo k chybě! Zkontrolujte, prosím, zda název není duplicitní.'
    }

    closeModal = () => {
        this.setState({showModal: false})
    }

    addNewGallery = () => {
            fetch(`${BACKEND.ADDRESS}/gallery/new`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    creationDate: this.state.date, folderName: this.state.title.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase(), title: this.state.title
                }),
                credentials: 'include'
            })
            .then(response => response.json())
            .then(json => {
                if(json.type === 'error'){
                  //  alert(json.message);
                    this.setState({showModal: true})
                }
                else{
                   this.props.fetchYears();
                }
            })
            .catch(error => this.setState({showModal: true})/*alert(error.message)*/);

    }

    setDate = (event) => {
        const date = new Date(event.target.value)
        this.setState({date: date, inputDate: date.toISOString().substring(0, 10)})
    }

    setTitle = (event) => {
        const title = event.target.value
        this.setState({title: title, correctValues: title.length > 0 })
    }

    render() {
        return (
            <div className="galleries-admin-panel">
                <Form>
                    <Form.Group as={Row} className="mb-3" controlId="exampleForm.ControlInput1">
                        <Form.Label column sm={12} md={12} lg={12} xl={1}>Název</Form.Label>
                        <Col sm={12} md={12} lg={12} xl={3}>
                            <Form.Control
                                type="text"
                                placeholder="Název galerie..."
                                autoFocus
                                value={this.state.title}
                                onChange={this.setTitle}
                            />
                        </Col>

                        <Form.Label column sm={12} md={12} lg={12} xl={1}>Datum</Form.Label>
                        <Col sm={12} md={12} lg={12} xl={3}>
                            <Form.Control
                                type="date"
                                value={this.state.inputDate}
                                onChange={this.setDate}
                            />
                        </Col>  

                        <Col sm={12} md={12} lg={12} xl={4} className='galleries-admin-panel-btn-column'>
                            <Button variant="primary" onClick={this.addNewGallery} style={{float: 'right'}}>Přidat novou galerii</Button>
                        </Col>
                        
                    </Form.Group>
                </Form>
               
                <ErrorModal show={this.state.showModal} onHide={this.closeModal} text={this.state.errorText}></ErrorModal>
            </div>
        );
    }
}

export default GalleriesAdminPanel;