import React from 'react';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchPublicPages } from '../actions/publicPages';
import { Navbar, Nav, Container, NavDropdown, Button, Form } from 'react-bootstrap';
import AuthModal from './AuthModal';
import history from '../history';

import { logout } from '../actions/account';



class CustomNavbar extends React.Component{

    state = {
        showModal: false,
        expanded: false
    }

    makeModalVisible = () => {
        this.setState({showModal: true})
    }

    closeModal = () => {
        this.setState({showModal: false})
    }

    componentDidMount(){  
        this.props.fetchPublicPages();
    }

    logout = () => {
        this.props.logout();
        history.push('/');
    }

    setExpanded = () => {
        this.setState({expanded: this.state.expanded ? false : true});
    }

    closeMenu = () => {
        this.setState({expanded: false}, this.props.onLinkClick());
    }
    
    render(){
        let navSize = 12 + this.props.account.loggedIn ? 15 : 0;
        for(let i = 0; i < this.props.publicPages.pages.length; i++){
            navSize += this.props.publicPages.pages[i].title.length;
        }

        let expandVal = 'sm';
        if(navSize > 70)
            expandVal = 'xxl';
        else if(navSize > 60)
            expandVal = 'xl';
        else if(navSize > 40)
            expandVal = 'lg'
        else if(navSize > 30)
            expandVal = 'md'
      /*  else if(navSize > 40)
            expandVal = 'sm'*/

        return(
            <React.Fragment>
                <Navbar expanded={this.state.expanded} style={{ minHeight: '68px', boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.75), 0px 5px 15px rgba(0, 0, 0, 0.1)'}} collapseOnSelect fixed='top' expand={expandVal} bg='dark' variant='dark'>
                    <Container>
                        <Navbar.Toggle aria-controls='responsive-navbar-nav' onClick={this.setExpanded}/>
                        <Navbar.Collapse id='responsive-navbar-nav'>
                            <Nav className="container-fluid">
                                {
                                    this.props.publicPages.pages.map((page, index) => {
                                        if(page.subMenus.length > 0){
                                            return (
                                                <NavDropdown title={page.title} id="collasible-nav-dropdown" style={{marginRight: '10px'}}>
                                                    {
                                                        page.subMenus.map((subPage, index) => {
                                                            return <NavDropdown.Item as={Link} to={subPage.url} key={index} onClick={this.closeMenu}>{subPage.title}</NavDropdown.Item>
                                                        })
                                                    }
                                                </NavDropdown>
                                            )
                                            
                                        }
                                        else{
                                            return <Nav.Link as={Link} to={page.url} key={index} onClick={this.closeMenu} style={{marginRight: '10px'}}>{page.title}</Nav.Link>
                                        }

                                        
                                    })
                                }
                                {
                                    this.props.account.loggedIn && 
                                    <Nav.Link as={Link} to={'/page-editor'} onClick={this.closeMenu}>Editace stránek</Nav.Link>
                                }
                            </Nav>
                            <Nav className="ml-auto">
                                {
                                    !this.props.account.loggedIn && 
                                    <Nav.Link as={Link} to={'#'} onClick={this.makeModalVisible} style={{marginRight: '15px', textDecoration: 'underline', color: 'rgba(255,255,255,.85)'}}>Přihlášení</Nav.Link>
                                }
                                {
                                    this.props.account.loggedIn && 
                                    <Nav.Link as={Link} to={'#'} onClick={this.logout} style={{marginRight: '15px', textDecoration: 'underline', color: 'rgba(255,255,255,.85)'}}>Odhlásit</Nav.Link>
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Container>

                </Navbar>
                <AuthModal show={this.state.showModal} onHide={this.closeModal}></AuthModal>
            </React.Fragment>
        )
    }
};


export default connect(
    ({ account, publicPages }) => ({ account, publicPages }),
    {fetchPublicPages, logout}
  )(CustomNavbar);