import React from "react";
import { BACKEND } from '../config';
import ErrorModal from "./ErrorModal";
import {Alert, Row, Col, Form, Button} from 'react-bootstrap';
import imageCompression from 'browser-image-compression';

class GalleryAdminPanel extends React.Component {

    state = {
        files: [],
        showModal: false,
        filesWithStatus: []
    }

    closeModal = () => {
        this.setState({showModal: false})
    }

    addNewImagesToGallery = async() => {
        const files = this.state.files;
        let filesWithStatus = this.state.filesWithStatus;

        for(let i = 0; i <files.length; i++){
            const compressedImage = await imageCompression(files[i], {
                maxSizeMB: 0.7,
                maxWidthOrHeight: 2000,
                useWebWorker: true
            });
            const compressedMiniature = await imageCompression(files[i], {
                maxSizeMB: 0.05,
                maxWidthOrHeight: 600,
                useWebWorker: true
            });
            const base64Image = await this.convertBase64(compressedImage);
            const base64Miniature = await this.convertBase64(compressedMiniature);
            await fetch(`${BACKEND.ADDRESS}/image/new`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({
                    galleryId: this.props.galleryId, imageName: files[i].name, base64Content: base64Image, base64Miniature: base64Miniature
                }),
                credentials: 'include'
            })
            .then(response => response.json())
            .then(json => {
                if(json.type === 'error'){
                    filesWithStatus[i].status = 2;
                    this.setState({filesWithStatus: filesWithStatus});
                }
                else{
                    filesWithStatus[i].status = 1;
                    this.setState({filesWithStatus: filesWithStatus});
                }
                
            })
            .catch(error => {
                filesWithStatus[i].status = 2;
                this.setState({filesWithStatus: filesWithStatus});
            });
        }    

        this.props.fetchGalleryImages();
    }
    

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader(file);
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            }

            fileReader.onerror = (error) => {
                reject(error);
            }
        });
    }

    uploadImages = (event) => {
        const files = event.target.files;
        let filesWithStatus = [];

        for(let i = 0; i < files.length; i++){
            filesWithStatus.push({name: files[i].name, status: 0});
        }
        this.setState({files: files, filesWithStatus: filesWithStatus});
    }


    render() {
        return (
            <div className="gallery-admin-panel">
                <Form>
                    <Form.Group as={Row} controlId="formFile" className="mb-2">
                        <Col xs={12} sm={8} md={6} style={{textAlign: 'left'}}>
                            <Form.Control type="file" onChange={this.uploadImages} multiple className='mb-2'/>
                        </Col>
                        <Col xs={12} sm={4} md={6} style={{textAlign: 'right'}}>
                            <Button variant="success" onClick={this.addNewImagesToGallery} className='mb-2'>Nahrát</Button>
                        </Col>
                    </Form.Group>  
                    <ErrorModal title={'chyba'} text={'chyby'} show={this.state.showModal} closeModal={this.closeModal}></ErrorModal>
                </Form>

                {
                    this.state.filesWithStatus.map((file, index) => {
                        if(file.status === 0)
                            return <Alert key={index} variant="primary">Soubor <strong>{file.name}</strong> je připraven k nahrání na server.</Alert>
                        else if(file.status === 1)
                            return <Alert key={index} variant="success">Soubor <strong>{file.name}</strong> byl úspěšně nahrán na server.</Alert>
                        return <Alert key={index} variant="danger">Při nahrávání souboru <strong>{file.name}</strong> na server došlo k chybě!</Alert>
                    })
                }
            </div>
        );
    }
}

export default GalleryAdminPanel;