import React, {useCallback, useEffect} from 'react';
import { useForm, ValidationError } from '@formspree/react';
import { ReCaptcha } from 'react-recaptcha-v3'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';


class ContactForm extends React.Component {

    constructor(props) {
        super(props);
    
        this.state = {
          recaptchaToken: '',
          name: '',
          email: '',
          message: '',
          errors: {},
          submitting: false,
          submitted: false
        };
    
        this.handleSubmit = this.handleSubmit.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
      }

      verifyCallback(token) {
        this.setState({ recaptchaToken: token });
      }
    
      async handleSubmit(event) {
        event.preventDefault();
      
        this.setState({ submitting: true });
      
        try {
          const response = await fetch('https://formspree.io/f/mrgvrbgd', {
            method: 'POST',
            body: JSON.stringify({
                name: this.state.name, email: this.state.email, message: this.state.message, 'g-recaptcha-response': this.state.recaptchaToken
            }),
            headers: {
              Accept: 'application/json',
            },
          });
      
          const responseData = await response.json();
      
          if (response.ok) {
            this.setState({ submitted: true });
          } else {
            this.setState({ errors: responseData.errors });
          }
        } catch (e) {
          console.error(e);
        } finally {
          this.setState({ submitting: false });
        }
      }
    
     updateName = (event) => {
        this.setState({name: event.target.value})
      }

      updateEmail = (event) =>{
        this.setState({email: event.target.value})
      }

      updateMessage = (event) => {
        this.setState({message: event.target.value})
      }
    
      render() {
        return (<>
            <Form className='contact-form'>
            <ReCaptcha
              sitekey="6LfnUpslAAAAAFZfeksatq_gCa8dHZsSW04I_7H2"
              action="submit"
              verifyCallback={this.verifyCallback}
            />
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Váš email..." value={this.state.email} onChange={this.updateEmail}/>
                    <Form.Text className="text-muted">
                        Pro odesílání emailů je využita služba Formspree, která je plně v souladu GDPR
                    </Form.Text>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicName">
                    <Form.Label>Jméno</Form.Label>
                    <Form.Control type="text" placeholder="Vaše jméno..." value={this.state.name} onChange={this.updateName}/>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicMessage">
                    <Form.Label>Zpráva</Form.Label>
                    <Form.Control as="textarea" placeholder="Text zprávy..." value={this.state.message} onChange={this.updateMessage} rows={2 + this.state.message.split("\n").length-1}/>
                </Form.Group>
            
                <Button variant="primary" onClick={this.handleSubmit}>
                    Odeslat
                </Button>
            </Form>

           
            {this.state.submitted && (
              <p>Vaše zpráva byla odeslána. Děkujeme!</p>
            )}
          </>
        );
      }
    
}

export default ContactForm;