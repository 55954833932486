import React from 'react';
import { BACKEND } from '../config';
import {Button, Form, Row, Col} from 'react-bootstrap';
import ErrorModal from './ErrorModal';
import { connect } from 'react-redux';

class ParishSheetForm extends React.Component {

    state = {
        base64Content: '',
        showModal: false,
        errorText: 'Při nahrávání farníčku došlo k chybě!'
    }

    closeModal = () => {
        this.setState({showModal: false})
    }
    
    addNewParishSheet = () => {
        fetch(`${BACKEND.ADDRESS}/pageContent/newParishSheet`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                base64Content: this.state.base64Content, pathToFile: 'farnicek.pdf'
            }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                //  alert(json.message);
                this.setState({showModal: true})
            }
            else{
                
            }
        })
        .catch(error => this.setState({showModal: true})/*alert(error.message)*/);
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader(file);
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            }

            fileReader.onerror = (error) => {
                reject(error);
            }
        });
    }

    uploadFile = async(event) => {
        const file = event.target.files[0];
        const base64 = await this.convertBase64(file);
        this.setState({base64Content: base64});
    }

    render() {
        return (
            <>
                {
                    this.props.account.loggedIn &&
                    <Form>
                        <Form.Group as={Row} controlId="formFile" >
                            <Form.Label column xs={12} sm={12} md={12} style={{textAlign: 'left'}}>Nahrání nového farníčku</Form.Label>
                            <Col xs={12} sm={12} md={12}>
                                <Form.Control type="file" size="sm" onChange={this.uploadFile}/>
                            </Col>
                            <Col xs={12} sm={12} md={12} style={{paddingTop: '10px'}}>
                                <Button variant="success" onClick={this.addNewParishSheet}>Uložit</Button>
                            </Col>
                        </Form.Group>  
                        <ErrorModal show={this.state.showModal} onHide={this.closeModal} text={this.state.errorText}></ErrorModal>
                    </Form>
                }
                
                
            </>
            
        );
    }
}

export default connect(
    ({ account }) => ({ account }),
    null
  )(ParishSheetForm);