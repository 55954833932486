import React from 'react';
import './pageEditor.css';
import { BACKEND } from '../config';
import {Button, Form, Row, Col} from 'react-bootstrap';
import imageCompression from 'browser-image-compression';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline"],
        [{'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466', 'grey', 'rgb(107, 107, 107)'] }, { background: [] }],
        [{ list:  "bullet" }],
        ["link"],
        ["clean"],
    ],
};

class Content extends React.Component{

    state = {
        pageContent: this.props.pageContent,
        subtitle: this.props.pageContent.subtitle  || "",
        isPublic: this.props.pageContent.isPublic,
        content: this.props.pageContent.content  || "",
        pathToFile: this.props.pageContent.pathToFile  || "",
        base64Content: "",
        edit: false,
        file: null
    }

    updateSubtitle = event => {
        this.setState({subtitle: event.target.value});
    }

    updateContent = event => {
        this.setState({content: event.target.value});
    }

    updateFile = (event) => {
        const file = event.target.files[0]
        this.setState({file: file, pathToFile: file.name});
    }

    convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader(file);
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            }

            fileReader.onerror = (error) => {
                reject(error);
            }
        });
    }

    updateIsPublic = event => {
        this.setState({isPublic: event.target.checked});
    }

    toggleEdit = () => {
        this.setState({edit: !this.state.edit});
    }

    updatePageContent = async() => {
        const file = this.state.file;
        let base64File, base64Miniature;

        if(file !== null){
            if(this.isImage(this.state.pathToFile)){
                const compressedImage = await imageCompression(file, {
                    maxSizeMB: 0.7,
                    maxWidthOrHeight: 2000,
                    useWebWorker: true
                });
                const compressedMiniature = await imageCompression(file, {
                    maxSizeMB: 0.2,
                    maxWidthOrHeight: 800,
                    useWebWorker: true
                });

                base64File = await this.convertBase64(compressedImage);
                base64Miniature = await this.convertBase64(compressedMiniature);
            }
            else{
                base64File = await this.convertBase64(file);
            }
        }

        fetch(`${BACKEND.ADDRESS}/pageContent/update`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                pageContentId: this.props.pageContent.id, 
                subtitle: this.state.subtitle, 
                isPublic: this.state.isPublic, 
                content: this.state.content, 
                pathToFile: this.state.pathToFile, 
                contentType: this.props.pageContent.contentType,
                base64Content: base64File,
                base64Miniature: base64Miniature
            }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                alert(json.message);
            }
            else{
                this.toggleEdit();
            }
        })
        .catch(error => alert(error.message));
    }

    removePageContent = () => {
        fetch(`${BACKEND.ADDRESS}/pageContent/remove`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                pageContentId: this.state.pageContent.id
            }),
            credentials: 'include'
        })
        .then(response => response.json())
        .then(json => {
            if(json.type === 'error'){
                alert(json.message);
            }
            else{
                this.props.updatePageContentsArray(this.props.index);
            }
        })
        .catch(error => alert(error.message));
    }

    isImage = (fileName) => {
        let exts = ['.jpg', '.gif', '.png', '.jpeg', '.bmp']
        return (new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$')).test(fileName.toLowerCase());
    }


    get SaveButton(){
        return <Button className="mb-2" variant="success" onClick={this.updatePageContent}>Uložit</Button>
    }

    get EditButton(){
        return <Button className="mb-2" variant="primary"  onClick={this.toggleEdit}>Editovat</Button>
    }


    render(){
        let typeText;
        let codeBlock;
        let imageTmp = this.state.pathToFile.split('.');
        let miniatureSrc = `${BACKEND.ADDRESS}/${imageTmp[0]}min.${imageTmp[1]}`;
        

        if(this.props.pageContent.contentType == 1){
            typeText = "Nadpis";
            codeBlock = this.state.edit ? <Form.Control type="text" placeholder="Podnadpis..." value={this.state.subtitle} onChange={this.updateSubtitle}/> : <h4>{this.state.subtitle}</h4>
        }
        else if(this.props.pageContent.contentType == 2 ){
            typeText = "Text";
            codeBlock = this.state.edit ? <Form.Control as="textarea" placeholder="Text obsahu..." rows={2 + this.state.content.split("\n").length-1} value={this.state.content} onChange={this.updateContent}/> : <div className='text-content'>{this.state.content}</div>
        }
        else if(this.props.pageContent.contentType == 3){
            typeText = "Soubor";
            codeBlock = this.state.edit ? <Form.Control type="file" onChange={this.updateFile}/> : 
            (
                this.isImage(this.state.pathToFile) ? <img style={{maxWidth: '80%', maxHeight: '30vh'}} src={miniatureSrc} /> :
                <a href={`${BACKEND.ADDRESS}/${this.state.pathToFile}`}>{this.state.pathToFile}</a>
            )
        }
        else if(this.props.pageContent.contentType == 4){
            typeText = "Odkaz";
            codeBlock = this.state.edit ? <Form.Control type="text" placeholder="Odkaz na stránky..." value={this.state.content} onChange={this.updateContent}/> : <a rel="noreferrer" target="_blank" href={this.state.content}>{this.state.content}</a>
        }
        else if(this.props.pageContent.contentType == 5){
            typeText = "Seznam";
            codeBlock = this.state.edit ? <Form.Control as="textarea" placeholder="Body seznamu oddělěny klávesou ENTER..." rows={2 + this.state.content.split("\n").length-1} value={this.state.content} onChange={this.updateContent}/> : <div className='text-content'>{this.state.content}</div>
        }
        else if(this.props.pageContent.contentType == 6){
            typeText = "Dvojice název - hodnota";
            codeBlock = this.state.edit ? <Form.Control as="textarea" placeholder="Formát 'název:hodnota'&#10;Jednotlivé dvojice odděleny klávesou ENTER..." rows={2 + this.state.content.split("\n").length-1} value={this.state.content} onChange={this.updateContent}/> : <div className='text-content'>{this.state.content}</div>
        }
        else if(this.props.pageContent.contentType == 7){
            typeText = "Mapa";
            codeBlock = this.state.edit ? <Form.Control type="text" placeholder="Zeměpisná šířka,zeměpisná délka..." value={this.state.content} onChange={this.updateContent}/> : <div className='text-content'>{this.state.content}</div>
        }
        else if(this.props.pageContent.contentType == 8){
            typeText = "Kontaktní formulář";
            codeBlock = <div className='text-content'>Mail bude odeslán na farnost.staravesno@gmail.com</div>
        }
        else if(this.props.pageContent.contentType == 9){
            typeText = "Textový obsah";
            codeBlock = this.state.edit ? <ReactQuill
                className='text-editor'
                value={this.state.content}
                theme='snow'
                onChange={(value) => this.setState({content: value})}
                modules={modules}
            /> : <div className="text-content" dangerouslySetInnerHTML={{__html: this.state.content}}></div>
        }

        return(
                <Form>
                    <Form.Group as={Row} className="mb-1" controlId="formGroupContent">

                            <Col xs={12} sm={3} md={2} lg={4}  style={{textAlign: 'left'}}>
                                <div className='page-content-row-text'>{typeText}</div>
                            </Col>

                            <Col xs={12} sm={9} md={10} lg={8} style={{textAlign: 'right'}}>
                                <Form.Check className="mb-2" style={{display: 'inline-block'}} label={this.state.isPublic ? "Veřejná" : "Neveřejná"} type="switch"  disabled={!this.state.edit} checked={this.state.isPublic} onChange={this.updateIsPublic}/>
                                {
                                    this.state.edit ? this.SaveButton : this.EditButton
                                }  
                                <Button className="mb-2" variant="danger" onClick={this.removePageContent}>Smazat</Button>
                            </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-2" controlId="formGroupContent">

                            <Col md={8}  style={{textAlign: 'left'}}>
                               {codeBlock}
                            </Col>
                    </Form.Group>
                </Form>
        )
    }
};

export default Content;